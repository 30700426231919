import * as React from 'react'


const WorkItemFull = ({title, subtitle, link, img, available}) => {
    if (available) {
        return (
            <a href={link} className="item-work twelve columns">
                <div className="item-wrapper">
                    <img className="thumbnail" src={img}></img>
                    <h6>{subtitle}</h6>
                    <h1>{title}</h1>
                </div>
            </a>
        )
    }
    else {
        return (
            <a href={link} className="item-work twelve columns">
                <div className="item-wrapper">
                    <img className="thumbnail" src={img}></img>
                    <h6>{subtitle}</h6>
                    <h1>{title}</h1>
                    <span className="soon">Coming Soon</span>
                </div>
            </a>
        )
    }
    
}

export default WorkItemFull
