import * as React from "react"
import memoji from '../images/memoji.png'
import {FiArrowUp} from 'react-icons/fi'

import '../styles/home.css'
import '../styles/skeleton.css'
import '../styles/normalize.css'

import Header from "../components/Header"
import Footer from "../components/Footer"
import Socials from "../components/Socials"
import WorkItemHalf from "../components/WorkItemHalf"
import WorkItemFull from "../components/WorkItemFull"
import Meta from "../components/Meta"

import faith from "../images/Thumbnails/Faith.png"
import daydreams from "../images/Thumbnails/Daydreams.png"
import spx from "../images/Thumbnails/SPX.png"
import nike from "../images/Thumbnails/Nike.png"
import met from "../images/Thumbnails/Met.png"
import gmail from "../images/Thumbnails/Gmail.png"
import presentations from "../images/Thumbnails/Presentations.png"
import Status from "../components/Status"

// markup
const IndexPage = () => {

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  return (
    <div className="container main">
      <title>Aritro Paul</title>
      <Meta></Meta>
      <Header></Header>
      <div className="header row">
      <div className="memoji twelve columns">
        <img class="memoji-image" src={memoji}></img>
      </div>
      <div className="content twelve columns">
        <div className="intro">
          <h6>HI, I AM ARITRO</h6>
        </div>
        <div className="headline">
          <h1>Product + UI designer from Delhi, IN.</h1>
          <h1>Studying HCI at University of Maryland.</h1>
        </div>
        <div className="bio">
          <h6>I specialize in making beautiful UI’s for mobile and web interfaces. </h6>
          <h6>As a side hobby, I am also an iOS Developer with a penchant for custom UIs.</h6>
        </div>
        <Status isactive={true} text="Looking for a Summer 2022 Internship"></Status>
      </div>
    </div>

    <div className="work">
      <div className="work-items row">
        <WorkItemHalf title="Faith" subtitle="PERSONAL PROJECT" link="/work/faith" img={faith} available={true}></WorkItemHalf>
        <WorkItemHalf title="Daydreams" subtitle="PERSONAL PROJECT" link="/work/daydreams" img={daydreams} available={true}></WorkItemHalf>
      </div>
      <div className="work-items row">
        <WorkItemHalf title="SPX" subtitle="SCHOOL PROJECT" img={spx} link="/work/spx" available={true}></WorkItemHalf>
        <WorkItemHalf title="Nike" subtitle="SCHOOL PROJECT" img={nike} link="/work/nike" available={true}></WorkItemHalf>
      </div>
      <div className="work-items row">
        <WorkItemFull title="The Met" subtitle="SCHOOL PROJECT" img={met}></WorkItemFull>
      </div>
      <div className="work-items row">
        <WorkItemFull title="Gmail" subtitle="SCHOOL PROJECT" img={gmail}></WorkItemFull>
      </div>
      <div className="work-items row">
        <WorkItemFull title="Presentations" subtitle="HACKATHON" img={presentations} link="/presentations" available={true}></WorkItemFull>
      </div>
      <div className="work-items row">
        <div className="item-work item-wrapper twelve columns website">
          <h1>and</h1>
          <h6>Designed and Developed</h6>
          <h1>this website</h1>
        </div>
      </div>
    </div>


    <div className="top">
      <button onClick={handleClick}>
        <FiArrowUp/>
        <br/>
        BACK TO TOP
      </button>
    </div>

    <Socials></Socials>

    <Footer></Footer>
  </div>
  )
}

export default IndexPage
