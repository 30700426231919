import React from 'react'

const Status = ({isactive, text}) => {

    if (isactive) {
        return (
            <div className="status row">
                <div className="four columns ind-container">
                    <div className="indicator-active"></div>
                    <h6 className="status-active">{text}</h6>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="status row">
                <div className="four columns ind-container-inactive">
                    <div className="indicator-inactive"></div>
                    <h6 className="status-inactive">{text}</h6>
                </div>
            </div>
        )
    }

}

export default Status

